.footer-container {
  background-color: #333;
  color: white;
  padding: 40px 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-logo-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  max-width: 150px; /* Adjust the size of the logo as needed */
  height: auto;
}

.footer-section {
  flex: 1;
  min-width: 200px;
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #fff;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #fff;
}

.footer-social-icons {
  display: flex;
  gap: 15px;
}

.footer-social-icons a {
  color: #ccc;
  font-size: 20px;
  transition: color 0.3s ease;
}

.footer-social-icons a:hover {
  color: #007bff;
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #444;
  font-size: 14px;
}

.footer-bottom p {
  margin: 0;
}

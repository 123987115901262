.fleet-container {
  background-size: cover;
  background-position: center;
  padding: 80px 20px;
  text-align: center;
  color: white;
  position: relative;
  min-height: 10vh; /* Ensure it covers the whole viewport */
  background-attachment: fixed; /* Parallax effect for the background */
  background-image: url("./fleet-bg.jpg");
}

.fleet-title {
  font-size: 36px;
  margin-bottom: 50px;
  color: white;
}

.fleet-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 0 40px;
}

.fleet-card {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fleet-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
}

.fleet-name {
  font-size: 22px;
  margin-bottom: 10px;
  color: white;
}

.fleet-description {
  font-size: 16px;
  color: #ddd;
}

@media (max-width: 768px) {
  .fleet-container {
    background-image: url();
    height: 100%;
    background-color: gray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

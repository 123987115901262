/* Basic styling for desktop navigation */
.nav-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: transparent;
  color: white;
}

.nav-logo-div {
  margin-left: 80px;
}
.nav-logo {
  font-size: 24px;
  color: black;
}
.logo {
  width: 150px;
  height: 70px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links li a {
  color: black;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #007bff;
}
.nav-toggle {
  display: none; /* Hidden by default */
}

/* Hide links and show the hamburger menu on small screens */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide navigation links by default on small screens */
    position: absolute;
    top: 70px;
    right: 0;
    background-color: white;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }

  .nav-links.active {
    display: flex; /* Show the links when the hamburger is clicked */
  }

  .nav-links li {
    margin: 10px 0;
  }

  .nav-links li a {
    font-size: 24px;
  }

  /* Hamburger icon visible on small screens */
  .nav-toggle {
    display: flex;
    font-size: 28px;
    cursor: pointer;
    z-index: 10;
    color: black;
  }
}

/* Hide the hamburger menu icon on larger screens */

@media only screen and (max-width: 600px) {
  .nav-container {
    padding: 10px 20px;
  }
  .nav-logo-div {
    margin: 0;
  }
}

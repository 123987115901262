.slider-container {
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 40px 0px;
}

.card {
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  background-color: #f9f9f9;
  margin: 0 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-text {
  font-size: 1.1em;
  font-style: italic;
}

.testimonial-author {
  font-size: 1em;
  font-weight: bold;
  margin-top: 10px;
}

.stars {
  margin-top: 10px;
}

.star {
  color: gold;
  font-size: 1.5em;
}

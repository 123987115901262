.hero-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-image: url("./hero.jpg");
  background-size: cover; /* Ensures the image covers the full container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
  opacity: 0.9;
}

/* This div will use flex to arrange children vertically */
.hero-container > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Takes full height of the hero container */
  width: 100%; /* Covers full width */
  position: relative;
}

.content {
  text-align: center;
  z-index: 10; /* Ensures the content appears above the image */
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.nyc-limo-service {
  color: black; /* Text color */
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif; /* Font stack */
  font-weight: 400; /* Normal font weight */
  font-size: 3rem; /* Large font size */
  padding: 5px; /* Add some padding around the text */
  border-radius: 3px; /* Slightly round the edges for a softer look */
}
.nyc-limo-service-family {
  color: black;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-weight: 400;
  font-size: 3rem;
}

.hero-button {
  z-index: 10;
  width: 200px;
  padding: 10px 20px;
  font-size: 1.5rem;
  color: black !important;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button-link {
  color: black;
}
.button-link:hover {
  color: white;
}

.hero-button:hover {
  background-color: black;
  color: white;
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.hero-info {
  color: white;
  border: 2px solid white;
  border-radius: 20px;
  padding: 20px 60px;
  align-self: center;
  margin: 20px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  z-index: 10; /* Ensures the info appears above the image */
}

.hero-info-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-info-icon {
  font-size: 30px;
  border: 2px solid white;
  padding: 15px;
  border-radius: 50%;
}

.hero-info-card-desc {
  display: block;
}

@media only screen and (max-width: 600px) {
  .hero-container {
    background: url("./hero.jpeg");
    height: 80vh;
    background-size: cover; /* Ensures the image covers the full container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat;
  }

  .hero-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
    gap: 12px;
  }
  .hero-info-icon {
    font-size: 20px;
    border: 2px solid white;
    padding: 5px;
    border-radius: 50%;
  }
  .hero-info-card-desc {
    display: none;
  }
  .nyc-limo-service {
    font-size: 2rem;
    font-family: cursive;
  }

  .hero-button {
    width: 150px;
    font-size: 16px;
    border: 1px solid white;
  }
  .hero-info {
    width: auto;
    padding: 10px 20px;
    border: none;
  }
  .hero-info-card-header {
    font-size: 12px;
    inline-size: max-content;
  }
  .hero-info-card {
    width: 100%;
  }
  .content {
    margin-top: 140px;
  }
  .nyc-limo-service-family {
    color: white;
    font-family: cursive;
    font-weight: 400;
    margin-top: 70px;
    font-size: 2rem;
  }
  .button-link {
    color: white;
  }
}

.services-container {
  background: whitesmoke;
  padding: 40px;
  text-align: center;
}

.services-title {
  color: black;
  font-size: 30px;
  margin-bottom: 40px;
  font-family: cursive;
}

.services-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 0 40px;
}

.card {
  position: relative;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}

.card-image {
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 50%;
  font-size: 24px;
}

.card-content {
  padding: 20px;
}

.card-content h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
}

.card-content p {
  font-size: 16px;
  color: #555;
}

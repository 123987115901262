/* Container with background image covering full page */
.reservation-container {
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Overlay for form with transparency */
.reservation-overlay {
  background-color: rgba(255, 255, 255, 0.8); /* White with transparency */
  padding: 40px 70px 50px 40px;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px); /* Optional blur effect behind the overlay */
}

.reservation-title {
  font-size: 32px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #333;
}

.step h3 {
  font-size: 22px;
  text-align: left;
  margin-bottom: 20px;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
  width: 90%;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  outline: none;
  color: #333;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.next-btn,
.previous-btn,
.submit-btn {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
}

.next-btn:hover,
.previous-btn:hover,
.submit-btn:hover {
  background-color: #0056b3;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.previous-btn {
  background-color: #6c757d;
}

.previous-btn:hover {
  background-color: #5a6268;
}
.error {
  color: red;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .reservation-overlay {
    padding: 30px;
  }

  .reservation-title {
    font-size: 28px;
  }

  .submit-btn {
    font-size: 16px;
  }
}

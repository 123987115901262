.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background-color: #f8f8f8;
  flex-direction: row; /* Default: two columns */
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.about-image {
  flex: 1;
  margin-right: 30px;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.about-content {
  flex: 1;
  max-width: 600px;
}

/* Styling for the heading and paragraphs */
.about-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Media query to make the layout responsive on small screens */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column; /* Change to single column */
    text-align: center; /* Center the text on small screens */
  }

  .about-image {
    margin-right: 0; /* Remove margin on small screens */
    margin-bottom: 20px; /* Add space below the image */
  }

  .about-content {
    max-width: 100%; /* Allow full width content on small screens */
  }
}
